<template>
  <div class="psychologicalConsulting">
    <div class="banner">
      <svg-icon icon-class="中国心理卫生协会logo" width="25%" height="25%" />
      <p class="above">中国心理卫生协会<br />心理咨询师专业技能培训项目</p>
      <div class="desc">
        作为落实十九大精神的两项具体部署，“健康中国行动”和“社会心理服务体系建设试点工作”都需要相当数量的能在基层从事心理健康服务的人才。中国心理卫生协会作为原人社部心理咨询师职业资格考试的技术支持单位，根据社会各界对心理咨询专业人才的需求，依托自有专家团队和师资力量，依照国家《心理咨询师国家职业标准》，采用2015、2017版国考教程体系，推出本“心理咨询师专业技能培训”项目。
      </div>
    </div>
    <div class="policy">
      <div class="publicTitle">
        <p class="zh">相关政策</p>
        <!-- <p class="en">RELEVANT POLICIE</p> -->
      </div>
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in policieList"
            :key="index"
          >
            <div class="policy-item">
              <img :src="`${item.pic}`" style="width:100%" />
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="curriculum-syllabus">
      <div class="publicTitle">
        <p class="zh">课程大纲</p>
        <!-- <p class="en">CURRICULUM SYLLABUS</p> -->
      </div>
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in courseSyllabus"
            :key="index"
          >
            <div class="left">
              <p class="title_zh">{{ item.title }}</p>
              <!-- <p class="title_en">{{ item.title_en }}</p> -->
            </div>
            <div class="right">
              <p
                v-for="particular in item.list"
                :key="particular.index"
                class="particular"
              >
                {{ particular.name }}
              </p>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="lecturer">
      <div class="publicTitle">
        <p class="zh">师资介绍</p>
        <!-- <p class="en">LECTURER</p> -->
      </div>
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in teacherList"
            :key="index"
          >
            <div
              class="left"
              :style="{ backgroundImage: `url(${item.pic})` }"
            ></div>
            <div class="right">
              <div class="name_title">
                <div class="name-zh">
                  {{ item.name_zh }}
                </div>
                <!-- <div class="name-cn">
                  {{ item.name_cn }}
                </div> -->
                <div class="line"></div>
              </div>
              <div class="info-title" v-for="title in item.titles" :key="title" v-html="title">
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="training-methods">
      <div class="publicTitle">
        <p class="zh">培训方式</p>
        <!-- <p class="en">TRAINING METHODS</p> -->
      </div>
      <div>
        <div class="trainingImg">Img</div>
        <div class="trainingDesc">
          线上学习
        </div>
      </div>
    </div>
    <div class="assessment_certificate">
      <div class="publicTitle">
        <p class="zh">考核与证书</p>
        <!-- <p class="en">ASSESSMENT AND CERTIFICATE</p> -->
      </div>
      <div class="content">
        <div class="examination">
          <div class="examination_item">
            <p class="item_title">考试形式</p>
            <p class="item_desc">
              全国统考，每年四次
            </p>
          </div>
          <div class="examination_item">
            <p class="item_title">考试时间</p>
            <p class="item_desc">每年3、6、9、12月</p>
          </div>
          <div class="examination_item">
            <p class="item_title">考试内容</p>
            <div class="item_desc">
              <p>考核：综合考试及论文撰写</p>
              <p>理论和操作技能：线上考试，学员完成全部课程学习方有资格参加</p>
              <p>论文撰写：由学员提供个人成长报告（2000字以上）</p>
            </div>
          </div>
        </div>
        <div class="certificate">
          <p class="certificateTitle">证书样本</p>
          <div class="certificateImg"></div>
          <p class="certificateDesc">
            证书由中国心理卫生协会颁发，统一编号，在项目授权网站 www.aijiad.com 进行查询。此证书表明持证人已经通过相关课程培训与考核，具备相应的专业知识能力、可作为能力评价、考核、用人单位聘用的参考依据。
          </p>
        </div>
      </div>
    </div>
    <div class="curriculum_fee">
      <p>
        课程指导价
        <span class="highLight">4880</span>
        元/人。
      </p>
      <p>包含培训课程费。</p>
      <p>考试考务管理费，证书工本费。</p>
    </div>
    <div class="faq">
      <p class="faq_title">心理咨询师专业技能证书培训<br />学员常见问题解答</p>
      <div class="faq_content">
        <div v-for="item in fqaList" :key="item.index">
          <p class="faq_q">{{ item.question }}</p>
          <p class="faq_a">
            {{ item.answer }}
          </p>
        </div>
      </div>
      <div class="moreButton">
        <svg-icon
          :icon-class="showAll ? 'shouqi' : 'zhankai'"
          width="25px"
          height="25px"
          @click="showAllFaq()"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";
import { pcPolicieList, pcLecturer, pcCourseSyllabus, fqaList } from "./data";
export default {
  data() {
    return {
      policieList: pcPolicieList,
      teacherList: pcLecturer,
      courseSyllabus: pcCourseSyllabus,
      fqaList: [],
      showAll: false,
    };
  },
  mounted() {
    this.fqaList = fqaList.filter((val, i) => {
      return i <= 1;
    });
    new Swiper(".swiper-container", {
      //direction: 'vertical', // 垂直切换选项
      //mousewheel: true, //滚轮
      autoplay: {
        delay: 5000,
      },
      // 如果需要分页器
      pagination: {
        el: ".swiper-pagination",
        clickable: true, // 分页器可以点击
      },
    });
  },
  methods: {
    showAllFaq() {
      this.showAll = !this.showAll;
      this.fqaList = this.showAll
        ? fqaList.filter((val, i) => {
            return i >= 0;
          })
        : fqaList.filter((val, i) => {
            return i <= 1;
          });
    },
  },
};
</script>
<style lang="scss" scoped>
$vw_base: 390;
// $vh_base: 1080;
@function vw($px) {
  //这个是移动端适配用到的
  @return ($px / $vw_base) * 100vw;
}
.psychologicalConsulting {
  .banner {
    height: 400px;
    background: url("~@/assets/img/mb_img/bg_心理咨询.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 60px 24px;
    color: #fff;
    .above {
      font-size: vw(22);
      font-weight: 600;
      text-align: center;
    }
    .desc {
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      color: rgba(255, 255, 255, 0.7);
    }
  }
  .policy {
    padding: 15px 24px;
    background-color: #f5f5f5;
    .swiper-container {
      // height: 260px;
      width: 100%;
      .swiper-slide + .swiper-slide {
        // margin-left: 5px;
      }
      .swiper-slide {
        // height: 210px;
        padding-bottom: 30px;
        display: flex;
        .policy-item {
          // height: 100%;
          width: 100%;
          // background-size: 100% 100%;
          // background-repeat: no-repeat;
        }
      }
    }
  }
  .curriculum-syllabus {
    padding: 15px 24px;
    .swiper-container {
      height: 230px;
      width: 100%;
      .swiper-slide + .swiper-slide {
        // padding-left: 5px;
      }
      .swiper-slide {
        height: 180px;
        display: flex;
        font-size: vw(17);
        .left {
          flex-basis: 140px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 15px;
          background-color: rgba(42, 66, 111, 1);
          .title_zh {
            font-size: vw(17);
            font-weight: 600;
            color: #ffffff;
          }
          .title_en {
            font-weight: 500;
            color: #b98862;
            margin-top: 10px;
          }
        }

        .right {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          color: #fff;
          font-weight: 400;
          color: #2a426f;
          padding: 0 30px;
          .particular + .particular {
            margin-top: 10px;
          }
        }
      }
    }
  }
  .lecturer {
    padding: 15px 24px;
    background-color: #f5f5f5;
    .swiper-container {
      height: 250px;
      width: 100%;
      .swiper-slide + .swiper-slide {
        // padding-left: 5px;
      }
      .swiper-slide {
        height: vw(200);
        display: flex;

        .left {
          flex-basis: 44%;
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .right {
          flex: 1;
          background: rgba(185, 136, 98, 1);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #fff;
          padding: 0 vw(5);
          .name_title {
            display: flex;
            flex-direction: column;
            // justify-content: center;
            align-items: center;
            margin-top: vw(5);
            .name-zh {
              font-size: vw(18);
            }
            .name-cn {
              font-size: vw(15);
              margin-top: 4px;
            }
            .line {
              width: 30px;
              height: 1px;
              background: #fff;
              margin: 5px 0;
            }
          }
          .info-title {
            font-size: vw(12);
            line-height: vw(18);
            text-align: center;
            color: #fff;
          }
        }
      }
    }
  }
  .training-methods {
    padding: 15px 24px;
    background-color: #fff;
    .trainingImg {
      width: 100%;
      height: 160px;
      background: url("~@/assets/img/mb_img/bg_培训方式.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .trainingDesc {
      padding-top: 15px;
      text-align: center;
      font-size: 15px;
      font-weight: 500;
      color: #282828;
    }
  }
  .assessment_certificate {
    padding: 15px 24px;
    background-color: #f5f5f5;
    .content {
      background-color: #f5f5f5;
      padding: 0px 15px;
      .examination {
        background-color: #fff;
        padding: 15px;
        .examination_item {
          .item_title {
            padding-left: 5px;
            border-left: 2px solid rgba(185, 136, 98, 1);
            font-size: 16px;
            font-weight: 600;
            color: #282828;
          }
          .item_desc {
            padding: 10px;
            font-size: 14px;
            font-weight: 400;
            color: #282828;
            p + p {
              margin-top: 5px;
            }
          }
        }
        .examination_item + .examination_item {
          margin-top: 15px;
        }
      }
      .certificate {
        height: 350px;
        background-image: linear-gradient(
          to right,
          rgba(168, 126, 100, 1),
          rgba(53, 67, 105, 1)
        );
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px;
        .certificateTitle {
          font-size: 16px;
          font-weight: 500;
          color: #ffffff;
        }
        .certificateImg {
          width: vw(300);
          height: vw(200);
          background: url("~@/assets/img/mb_img/bg_合格证书.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
        .certificateDesc {
          font-size: 14px;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.7);
          text-align: center;
        }
      }
    }
  }
  .curriculum_fee {
    text-align: center;
    font-size: 16px;
    font-weight: 550;
    color: #282828;
    padding: 15px 24px;
    .highLight {
      font-weight: 700;
      font-size: 18px;
      color: rgba(185, 136, 98, 1);
    }
    p + p {
      margin-top: 5px;
    }
  }
  .faq {
    padding: 30px 24px;
    background: #f5f5f5;
    .faq_title {
      padding: 20px 50px;
      text-align: center;
      background: url("~@/assets/img/mb_img/bg_常见问题.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      line-height: 25px;
    }
    .faq_content {
      .faq_q {
        font-size: 16px;
        font-weight: 600;
        color: #b98862;
        margin-top: 18px;
      }
      .faq_a {
        font-size: 14px;
        font-weight: 500;
        color: #282828;
        margin-top: 10px;
      }
    }
    .moreButton {
      display: flex;
      justify-content: center;
      margin-top: 15px;
    }
  }
  .publicTitle {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: #282828;
    margin-bottom: 15px;
    p {
      margin: 0;
      padding: 0;
    }
    .zh {
      font-size: 24px;
      font-weight: 600;
    }
    .en {
      font-size: 16px;
      font-weight: 400;
    }
  }
}
</style>
